import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Wrapper } from '../components/Wrapper'

export const AboutPageTemplate = ({ title, content, contentComponent, image }) => {
    const PageContent = contentComponent || Content

    return (
        <Wrapper>
            <div
                className="full-width-image-container margin-top-0"
                style={{
                    backgroundImage: `url('/img/about-index.jpg')`,
                    backgroundAttachment: `fixed`,
                }}
            >
                <h2
                    className="has-text-weight-bold is-size-1"
                    style={{
                        boxShadow: '0.5rem 0 0 #00640a, -0.5rem 0 0 #00640a',
                        backgroundColor: '#00640a',
                        color: 'white',
                        padding: '0.25rem',
                    }}
                >
                    {title}
                </h2>
            </div>
            <div className="column is-12">
                <PageContent className="content" content={content} />
            </div>
        </Wrapper>
    )
}

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const AboutPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <AboutPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
                image={post.frontmatter.image}
            />
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
