import React from 'react'

export const Wrapper = props => (
    <section className="section" style={{ paddingTop: 0 }}>
        <div className="container">
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </section>
)
